import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer/Footer";
import HeroSection from "../../../components/HeroSection/HeroSection";
import Purello2 from "../../../assets/images/purello/Purello2.webp";
import QRCodeTemplate from "../../../components/QRCodeTemplate/QRCodeTemplate";
import Nav from "../../../components/Nav/Nav";

const QRCodePurelloJose: React.FC = () => {
  const { t } = useTranslation();

  const description = `Acabei de receber GRATUITAMENTE uma máquina de aromaterapia no valor de 150 euros. \nClique no link: https://bit.ly/PURELLO_MANUELA \nE em seguida responda a um breve questionário para receber GRATUITAMENTE a sua máquina. \nEsta máquina ajuda a melhorar a qualidade do ar, promove o bem-estar e é ótima para alergias e problemas respiratórios. Dê a sua opinião sobre o nosso purificador de ar e receba gratuitamente a sua máquina de aromaterapia. (sujeito a qualificação por parte da empresa)`;

  return (
    <>
      <Nav />
      <HeroSection
        title={t("purello_form_page.title")}
        subtitle=""
        backgroundImage={Purello2}
      />
      <QRCodeTemplate description={description} />
      <Footer />
    </>
  );
};

export default QRCodePurelloJose;
