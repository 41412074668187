import React, { useState } from "react";
import styles from "./QRCodeTemplate.module.css";

interface QRCodeTemplateProps {
  description: string;
}

const QRCodeTemplate: React.FC<QRCodeTemplateProps> = ({ description }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(description)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Reset copied state after 2s
      })
      .catch((err) => console.error("Failed to copy text: ", err));
  };

  return (
    <section className={styles.QRCodeTemplateSection}>
      <div>
        <h1 className={styles.title}>
          Obrigado por aceitar a nossa visita. Para ganhar o prémio tem de
          postar publicamente no facebook a foto com o seu prémio com o seguinte
          texto e link escrito em baixo durante 16 dias:
        </h1>
        <p className={styles.description}>
          {description.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </p>
        <div className={styles.buttonContainer}>
          <button onClick={handleCopy} className={styles.copyButton}>
            {copied ? "Copiado!" : "Copiar Texto"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default QRCodeTemplate;
