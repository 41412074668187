import React from "react";
import { useTranslation } from "react-i18next";
import Nav from "../../components/Nav/Nav";
import HeroSection from "../../components/HeroSection/HeroSection";
import Footer from "../../components/Footer/Footer";
import HistorySection from "../../components/HistoryComponents/HistorySection/HistorySection";
import HistoryHero from "../../assets/images/historyhero.webp";

const HistoryPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Nav />
      <HeroSection
        title={t("history_page.title")}
        subtitle=""
        backgroundImage={HistoryHero}
      />
      <HistorySection />
      <Footer />
    </>
  );
};

export default HistoryPage;
