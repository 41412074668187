import React from "react";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/Footer/Footer";
import HeroSection from "../../../components/HeroSection/HeroSection";
import FeriasAlgarve from "../../../assets/images/campaigns/FeriasAlgarve.webp";
import QRCodeTemplate from "../../../components/QRCodeTemplate/QRCodeTemplate";
import Nav from "../../../components/Nav/Nav";

const QRCodeVacationJose: React.FC = () => {
  const { t } = useTranslation();

  const description = `Férias. Quem não gosta!!!???\nE porque merecemos, assisti sem compromisso, à apresentação do equipamento de higiene e saúde, por dar a minha opinião, recebi grátis um voucher de férias com condições exclusivas.\n\nTambém queres? Carrega no link abaixo.\nhttps://bit.ly/EstadiavManuelaejosécarlos`;

  return (
    <>
      <Nav />
      <HeroSection
        title={t("vacation_form_page.title")}
        subtitle=""
        backgroundImage={FeriasAlgarve}
        variant="home"
      />
      <QRCodeTemplate description={description} />
      <Footer />
    </>
  );
};

export default QRCodeVacationJose;
